import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import Layout from '../components/Layout'

const MissionProposal = () => {
  const [params, setParams] = useState('')

  const location = useLocation();

  useEffect(() => {
    console.log(location.search)
    setParams(queryString.parse(location.search))
  }, [location.search])

  return (
    <Layout>
      <Helmet>
        <title>Vous avez une mission pour nos indépendants ?</title>
        <meta name="description" content="" />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Déposer votre projet" />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="" />
      </Helmet>

      <div style={{ height: '100vh', minHeight: '750px' }}>
        <iframe src={`https://tally.so/r/m681WN?${queryString.stringify(params)}`} width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" title="Vous avez une mission pour nos indépendants ?" />
      </div>
    </Layout>
   
  )
}

export default MissionProposal